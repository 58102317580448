<template>
	<div class="news-page-container" id="news">
		<div class="header-page">
			<headerPage @langChange="langChange"></headerPage>
		</div>
		<div class="news-page-first">
			<img
				v-if="langType == 'zh-CN' && serviceInfo.detailTopImg.cn"
				:src="fileUrl + serviceInfo.detailTopImg.cn"
				class="first-img"
			/>
			<img
				v-if="langType == 'zh-TW' && serviceInfo.detailTopImg.hk"
				:src="fileUrl + serviceInfo.detailTopImg.hk"
				class="first-img"
			/>
			<img
				v-if="langType == 'en-US' && serviceInfo.detailTopImg.en"
				:src="fileUrl + serviceInfo.detailTopImg.en"
				class="first-img"
			/>
			<img
				v-if="langType == 'en-KH' && serviceInfo.detailTopImg.kh"
				:src="fileUrl + serviceInfo.detailTopImg.kh"
				class="first-img"
			/>
		</div>

		<div class="list-page-second">
			<div class="second-main">
				<div
					class="details-every"
					v-for="(item, index) in serviceInfo.serviceItemDetail"
					:key="index"
					@click="checkServiceDetails(item)"
				>
					<div class="details-font" v-if="langType == 'zh-CN'">
						{{ item.cn }}
					</div>
					<div class="details-font" v-if="langType == 'zh-TW'">
						{{ item.hk }}
					</div>
					<div class="details-font" v-if="langType == 'en-US'">
						{{ item.en }}
					</div>
					<div class="details-font" v-if="langType == 'en-KH'">
						{{ item.kh }}
					</div>
					<div class="details-img">
						<img src="../assets/imgs/serviceList/right.png" />
					</div>
				</div>
			</div>
		</div>
		<div style="position: absolute;bottom: 0;left: 0;right: 0;">
			<footerPage></footerPage>
		</div>
	</div>
</template>

<script>
import headerPage from '@/components/headerPage';
import footerPage from '@/components/footerPage';
import { mapState } from 'vuex';
import { getCountryValue } from '@/utils/index';
export default {
	components: {
		headerPage,
		footerPage,
	},
	data() {
		return {
			fileUrl: this.$store.state.baseUrl,
			langType: localStorage.getItem('DefaultLanguage') || 'zh-CN',
			serviceInfo: {
				serviceItemDetail: [],
				detailTopImg: {},
			},
		};
	},
	computed: {
		...mapState(['curCountry']),
	},
	created() {
		if (this.$route.query) {
			let id = this.$route.query.id;
			if (id) {
				this.getServicetList(id);
			}
		}
	},
	methods: {
		getServicetList(id) {
			let url = this.fileUrl + `/webConfigInfo?id=${id}`;
			this.$axios.get(url).then((res) => {
				let data = res.data;
				if (data.detailTopImg) {
					this.serviceInfo.detailTopImg = JSON.parse(data.detailTopImg);
				}
				if (data.serviceItemDetail) {
					let serviceItemDetail = JSON.parse(data.serviceItemDetail);
					this.serviceInfo.serviceItemDetail = serviceItemDetail.filter(
						(item) => {
							return item.showCountry.includes(this.curCountry);
						}
					);
				}

				console.log(this.serviceInfo);
			});
		},

		langChange(value) {
			this.langType = value;
		},
		// 项目详情
		checkServiceDetails(item) {
			if (item.title) {
				let id = item.id;
				this.$router.push({
					path: '/details',
					query: {
						select: 3,
						id: id,
						country: getCountryValue(this.curCountry),
					},
				});
			}

			this.backTop();
		},
		// 页面回到顶部
		backTop() {
			let timer = null;
			cancelAnimationFrame(timer);
			timer = requestAnimationFrame(function fn() {
				var oTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (oTop > 0) {
					document.body.scrollTop = document.documentElement.scrollTop =
						oTop - 100;
					timer = requestAnimationFrame(fn);
				} else {
					cancelAnimationFrame(timer);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.news-page-container {
	min-height: 100vh;
	position: relative;
	z-index: 0;

	.news-page-first {
		.first-img {
			width: 100%;
			object-fit: cover;
		}
	}

	.list-page-second {
		margin-top: 80px;
		padding-bottom: 100px;

		.second-title {
			width: 90%;
			display: flex;
			justify-content: center;
			color: #755793;

			img {
				height: 18px;
			}
		}

		.second-main {
			.details-every {
				background-color: #f2f3f5;
			}
		}
	}
}

@media screen and (min-width: 1781px) {
	.header-page {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.news-page-first {
		.first-img {
			height: inherit;
		}
	}

	.list-page-second {
		.second-title {
			margin: 50px auto;
			font-size: 30px;
			letter-spacing: 3;

			.img-left {
				margin-right: 30px;
			}

			.img-right {
				margin-left: 30px;
			}
		}

		.second-main {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.details-every {
				width: 49%;
				height: 116px;
				margin-bottom: 20px;
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.details-font {
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 25px;
					color: #272729;
					text-align: left;
					font-weight: 400;
				}

				.details-img {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.second-phone-title {
		display: none;
	}
}

@media screen and (min-width: 1499px) and (max-width: 1780px) {
	.header-page {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.news-page-first {
		.first-img {
			height: inherit;
		}
	}

	.list-page-second {
		.second-title {
			margin: 50px auto;
			font-size: 30px;
			letter-spacing: 3;

			.img-left {
				margin-right: 30px;
			}

			.img-right {
				margin-left: 30px;
			}
		}

		.second-main {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.details-every {
				width: 49%;
				height: 116px;
				margin-bottom: 20px;
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.details-font {
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 25px;
					color: #272729;
					text-align: left;
					font-weight: 400;
				}

				.details-img {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.second-phone-title {
		display: none;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
	.header-page {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.news-page-first {
		.first-img {
			height: inherit;
		}
	}

	.list-page-second {
		.second-title {
			margin: 50px auto;
			font-size: 30px;
			letter-spacing: 3;

			.img-left {
				margin-right: 30px;
			}

			.img-right {
				margin-left: 30px;
			}
		}

		.second-main {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.details-every {
				width: 49%;
				height: 116px;
				margin-bottom: 20px;
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.details-font {
					width: 90%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 25px;
					color: #272729;
					text-align: left;
					font-weight: 400;
				}

				.details-img {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.second-phone-title {
		display: none;
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.header-page {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.news-page-first {
		.first-img {
			height: inherit;
		}
	}

	.list-page-second {
		.second-title {
			margin: 50px auto;
			font-size: 30px;
			letter-spacing: 3;

			.img-left {
				margin-right: 30px;
			}

			.img-right {
				margin-left: 30px;
			}
		}

		.second-main {
			width: 90%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.details-every {
				width: 49%;
				height: 116px;
				margin-bottom: 20px;
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.details-font {
					width: 90%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 22px;
					color: #272729;
					text-align: left;
					font-weight: 400;
				}

				.details-img {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.second-phone-title {
		display: none;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.header-page {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background-color: #fff;
	}

	.news-page-first {
		padding-top: 60px;

		.first-img {
			height: inherit;
		}
	}

	.list-page-second {
		.second-title {
			margin: 50px auto;
			font-size: 30px;
			letter-spacing: 3;

			.img-left {
				margin-right: 30px;
			}

			.img-right {
				margin-left: 30px;
			}
		}

		.second-main {
			width: 90%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.details-every {
				width: 49%;
				height: 116px;
				margin-bottom: 20px;
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.details-font {
					width: 90%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 20px;
					color: #272729;
					text-align: left;
					font-weight: 400;
				}

				.details-img {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.second-phone-title {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.news-page-container {
		.header-page {
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			background-color: #fff;
		}

		.news-page-first {
			padding-top: 60px;

			.first-img {
				min-height: 210px;
			}
		}

		.list-page-second {
			margin-top: 20px;

			.second-title {
				display: none;
				margin: 20px auto;
				font-size: 18px;
				letter-spacing: 3;

				.img-left {
					margin-right: 10px;
				}

				.img-right {
					margin-left: 10px;
				}

				img {
					height: 12px;
				}
			}

			.second-phone-title {
				display: block;
				width: 100%;
				height: 32px;
				margin: 25px auto;
				line-height: 32px;
				color: #fff;
				background-color: #755793;
				font-size: 16px;
			}

			.second-main {
				width: 100%;
				margin: 0 auto;
				margin-bottom: 20px;

				.details-every {
					width: 100%;
					// height: 116px;
					height: auto;
					margin-bottom: 10px;
					padding: 20px 20px;
					display: flex;
					justify-content: space-between;
					cursor: pointer;

					.details-font {
						width: 90%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						font-size: 14px;
						color: #272729;
						text-align: left;
						font-weight: 400;
					}

					.details-img {
						display: flex;
						flex-direction: column;
						justify-content: center;

						img {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}
}
</style>
